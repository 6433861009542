@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1180px;
  margin: auto;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.topMargin {
  margin-top: 5%;
}

h3 {
  font-size: 25px !important;
  color: #25b92b !important;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

.dashboard-container {
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 30px;
  background-color: #fff;
  min-height: 90vh;
  border-radius: 6px;
}

.head-container {
  display: flex;
  justify-content: space-between;
}

.head-container h1 {
  font-size: 18px;
  color: #6aab9c;
}

.mini-container {
  max-width: 1200px;
  margin: auto;
}

/* --------------Loader--------------  */

.spinner {
  width: 300px;
  height: 300px;
  display: flex !important;
  position: absolute;
  left: 45%;
  top: 48%;
  background-color: transparent;
}

.spinner span {
  font-size: 2rem;
  animation: fade 1s linear 0s infinite;
  padding-right: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #fb8a57;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.completed {
  font-size: 2rem;
  color: #03fc4e;

  animation: bigger 1s linear;
}

@keyframes bigger {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* --------------Loader--------------  */

/* --------------Dashboard--------------  */

.sidebar {
  text-align: center !important;
  background: #fff !important;
  position: sticky !important;
  overflow: auto !important;
  height: 90vh !important;
  top: 0 !important;
  left: 0 !important;
}

.hammenu {
  text-align: end !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.side-nav {
  background-color: #040408 !important;
  list-style: none !important;
  margin: 5px !important;
  border-radius: 10px !important;
  padding: 7px !important;
  text-align: start !important;
  height: 60vh !important;
  margin-top: 20px !important;
}

li.ant-menu-item {
  cursor: pointer;
  font-weight: 600 !important;
  padding: 5px;
  color: white;
  margin-top: 10px;
}

li.ant-menu-item:hover {
  background-color: #f2f6fc !important;
  border-radius: 5px !important;
  color: black !important;
}

.ant-form-item-explain-error {
  color: #fff !important;
}

.ant-form-item-required {
  color: #fff !important;
}

svg.ant-menu-item-icon {
  font-weight: bold !important;
  margin-right: 10px !important;
}

svg {
  cursor: pointer !important;
}

.main-content {
  background-color: #f2f6fc !important;
  padding: 10px !important;
}

/* --------------Dashboard--------------  */

/* --------------Responsive--------------  */

@media screen and (max-width: 991px) {
  .home-image {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)),
      url("./resources/images/Suee.jpg") !important;
    background-size: cover !important;
    background-position: center center !important;
  }

  .container {
    padding: 10px;
  }
  .home-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .footer-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .contact-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .trekking-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .subregion-grid-img {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .team-details {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .region-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .second-section {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .second-section-p {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .second-section-p > div:first-child {
    order: 2;
  }

  .second-section-p > div:last-child {
    order: 1;
  }

  .third-section {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .fifth-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .showImage img {
    width: 100% !important;
    height: 100% !important;
  }

  .trek-btn {
    visibility: hidden !important;
  }

  .container p {
    width: 100% !important;
  }

  #tt-tt {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .home-image {
    min-height: 90vh !important;
  }
}

/* --------------Responsive--------------  */
