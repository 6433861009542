@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.home {
  min-height: 90vh;
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.home-image {
  height: 550px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)),
    url("../../resources/images/mountin.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.home-grid h1 {
  color: #fff;
  margin-top: 9.5%;
  font-size: 70px;
  margin-bottom: 50px;
  font-family: "Poppins", sans-serif;
}

.home-grid p {
  color: #d9d9d9;
  font-size: 40px;
  /* line-height: 0px; */
  margin-top: -20px;
  font-family: "Poppins", sans-serif;
}

.second-section {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}

.second-section h4 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-bottom: 3px solid #2569b9;
  position: relative;
}

.second-section p {
  font-size: 18px;
  position: relative;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}
.second-section img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.second-section-p {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}

.second-section-p h4 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-bottom: 3px solid #2569b9;
  position: relative;
}

.second-section-p p {
  font-size: 18px;
  position: relative;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}
.second-section-p img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.third-section {
  margin-top: 10%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}

.card-comp {
  box-shadow: 0px 0px 4px 0px rgb(85, 79, 79);
  padding: 10px;
  padding-top: 20px;
  border: 1px solid #ccc;
  background-color: #ecf5ff;
}

.card-comp h1 {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #25b92b;
}

.card-comp p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  margin-top: 10px;
  color: rgb(77, 68, 68);
}

.icon-tick {
  font-weight: bold !important;
  color: #25b92b;
}

.tick-flex {
  margin-top: 10px;
  display: flex;
  gap: 0.4rem;
}

.tick-flex p {
  font-weight: bold;
  margin-top: 2px;
}

.fourth-section {
  margin-top: 50px;
}

.fourth-section h2 {
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: bold;
  color: #25b92b;
}

.second-section h4 {
  font-size: 28px;
}

.fourth-btn {
  padding: 10px 20px;
  background: #2569b9;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #fafafa;
  transition: 0.5s;
  letter-spacing: 1px;
}

.fifth-section {
  margin-top: 8%;
}

.fifth-section h2 {
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: bold;
  color: #25b92b;
}

.fifth-section p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.fifth-grid {
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
}

.fifth-card {
  box-shadow: 0px 0px 4px 0px rgb(85, 79, 79);
  padding: 10px;
  padding-top: 18px;
  background-color: #ecf5ff;
}

.fifth-card p {
  text-align: justify;
  font-size: 13px;
}

.quote-icon {
  color: #2569b9;
}

.person {
  margin-top: 10px;
  width: 80px;
  height: 80px;
  border: 2px solid #b98525;
  border-radius: 50px;
}

.role-div {
  margin-top: 10px;
}

.role-div p {
  line-height: 0;
  font-size: 16px;
}
